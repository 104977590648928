import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import UserEdit from "./userEdit";
import Skeleton from "react-loading-skeleton";
import { BASE_URL } from "../config";
import "react-loading-skeleton/dist/skeleton.css";
import adminDumny from "../assets/images/adminDumny.svg";
import DeletePopUp from "../components/deletePopUp";
const UserList = (back) => {
  useEffect(() => {
    let isAdmin = ["admin"].includes(localStorage.getItem("roles"));
    
    // If roles is not admin, redirect to dashboard page
    if (!isAdmin) {
      window.location = window.location.origin + "/membership";
      return false;
    }
  });
  const [profileImages, setProfileImages] = useState({});
  const [userDataList, setUserDataList] = useState([]);
  const [showUserDetail, setShowUserDetail] = useState("");
  const [loading, setLoading] = useState(false);
  const sortRef = useState(null);
  const sortOrderRef = useState(null);
  const [isProfile, setIsProfile] = useState();
  const [isDelete, setIsDelete] = useState(false);
  const [userId,setUserId]=useState();
  const[userName,setUserName]=useState();
  const navigate = useNavigate();
const[adminemail,setAdminemail]=useState();
  const goBack = () => {
    setShowUserDetail();
    // back(11)
  };

  const getUsersList = (event) => {
    if (sortRef.current === undefined) {
      var sort_by = "updatedAt";
      var sort_order_by = "-1";
    } else {
      var sort_by = sortRef.current.value;
      var sort_order_by = sortOrderRef.current.value;
    }

    setLoading(true);
    axios({
      headers: {
        "x-access-token": localStorage.getItem("auth-token"),
      },
      url: `${BASE_URL}/user/list`, //your url
      method: "GET",
      params: { sort: sort_by, order: sort_order_by },
    })
      .then((res) => {
        setLoading(false);
        console.log(res?.data?.data?.data);
        setUserDataList(res?.data?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getUsersList();
  }, []);

  useEffect(() => {
    getUsersList();
  }, [showUserDetail]);
  const yes = ()=>{
    deleteForumPost(userId);
  }
  const cancel = ()=>{
    setIsDelete(false);
  }
  const deleteForumPost = (formData) => {
    // console.log(formData._id);
    // const confirmed = window.confirm("Are you sure you want to delete?");
    // if (confirmed) {
      axios({
        headers: {
          "x-access-token": localStorage.getItem("auth-token"),
        },
        url: `${BASE_URL}/user/deleteusr/${formData._id}`, //your url
        // url: `${BASE_URL}/user/deleteusr`, //your url
        method: "POST",
        // params: { id: formData._id },  
      })
        .then((res) => {
          setLoading(false);
        getUsersList();
        setIsDelete(false);
        back(11);
        })
        .catch((err) => {
          setLoading(false);
        });
    // }
  };
  const fetchProfileImage = (id) => {
    axios({
      headers: {
        "x-access-token": localStorage.getItem("auth-token"),
      },
      url: `${BASE_URL}/user/profile/image/${id}`,
      method: "GET",
      responseType: "blob",
    })
      .then((res) => {
        const url = URL.createObjectURL(res.data);
        setProfileImages((prevImages) => ({ ...prevImages, [id]: url }));
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          console.log("there is no image with this user");
        }
      });
  };
  useEffect(() => {
    userDataList.forEach((user) => {
      fetchProfileImage(user._id);
    });
    setAdminemail(localStorage.getItem("email"))
  }, [userDataList]);

  let isAdmin = localStorage.getItem("roles") === "admin";

  return (
    <div className="dark:bg-slate-900 min-h-screen pb-4">
        {isDelete && <DeletePopUp data={`Are you Confirm to delete Mr ${userName} data Permanently from Your Database ! `} yes={yes} cancel={cancel}/>}
     
      {showUserDetail ? (
        localStorage.getItem("roles") === "admin" ? (
          <UserEdit
            forumData={showUserDetail}
            goBack={goBack}
            getUsersList={getUsersList}
          />
        ) : (
          ""
        )
      ) : (
        <div className="pt-10">
          <div className="mb-2 md:mb-6 md:my-0 ">
            <div className="flex justify-between items-center border-b border-blue-200 border-opacity-20 h-14 mb-8 mx-8">
              <div className="">
                <label
                  for="sort"
                  className="font-normal text-gray-600"
                  style={{ marginLeft: "10px", marginRight: "5px" }}
                >
                  Sort By
                </label>
                <select
                  id="sort"
                  className="select-default"
                  name="sort"
                  onChange={() => getUsersList()}
                  ref={sortRef}
                >
                  <option value="createdAt">Created At</option>
                  <option value="firstName">Name</option>
                </select>

                <label
                  for="sort"
                  className="font-normal text-gray-600 dark:text-gray-400"
                  style={{ marginLeft: "10px", marginRight: "5px" }}
                >
                  Order By
                </label>
                <select
                  id="sort_order"
                  name="sort_order"
                  className="select-default"
                  onChange={() => getUsersList()}
                  ref={sortOrderRef}
                >
                  <option value="1">Asc &uarr;</option>
                  <option value="-1">Desc &darr;</option>
                </select>
              </div>
              
            </div>
            
          </div>
         
          {loading ? (
            <div className="mb-6 mx-2 md:my-6 md:mx-10 rounded">
              <Skeleton
                className="mb-4"
                baseColor="#ccc"
                highlightColor="#fff"
                height={140}
                count={4}
              />
            </div>
          ) : (
            <div className="px-8 overflow-x-auto">
                <div className="flex text-xs  justify-end" >
            <div  className="bg-slate-300 w-3 h-4 mr-2 "></div>
                Company user
              </div>
              <table
                class="bg-white w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse rounded-xl"
                cellPadding={0}
                cellSpacing={0}
              >
                <thead class="text-xs text-gray-700 uppercase bg-[#FFF5F3] dark:text-gray-400 rounded-xl">
                  <tr className="text-slate-900 border-0">
                    <th scope="col" class="p-3 rounded-tl-xl">
                      Profile Image
                    </th>
                    <th scope="col" class="p-3">
                      Name
                    </th>
                    <th scope="col" class="p-3">
                      Email
                    </th>

                  
                    <th scope="col" class="p-3 text-center">
                      Age
                    </th>
                    <th scope="col" class="p-3 text-center">
                      Weight
                    </th>
                   
                    
                    <th scope="col" class="p-3 text-center">
                      Status
                    </th>
                    <th scope="col" class="p-3 text-center rounded-tr-xl">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userDataList.map((data) => (
                    <tr
                      class={!data?.company?"text-slate-900 border-0 border-solid border-b-2 border-gray-300 last:border-b-0 "
                        :
                        "bg-slate-200 text-slate-900 border-0 border-solid border-b-2 border-gray-300 last:border-b-0"}
                      // onClick={() => setShowUserDetail(data)} bg-zinc-300
                      key={data._id}
                    >
                      <td class="p-3 ">
                        <img
                          src={profileImages[data._id] || adminDumny}
                          alt="Profile"
                          className="h-10 w-10 rounded-full"
                        />
                      </td>
                      <td class="p-3">
                        {data?.firstName} {data?.lastName}
                      </td>
                      <td class="p-3 max-w-4 w-1/4 whitespace-nowrap overflow-hidden text-ellipsis ...">{data?.email} </td>
                    
                      <td class="p-3 text-center">{data?.age} </td>
                     
                      <td class="p-3 text-center">{data?.weight} </td>
                      <td class="p-3 text-center">
                        {data?.status === 1 ? "Active" : "Inactive"}{" "}
                      </td>
                      {/* <td class="p-3">{data?.status===2&&"Unactive"} </td> */}
                      <td class="p-3">
                        <div className="flex">
                          <div
                            onClick={() => setShowUserDetail(data)}
                            className="md:w-[100%] text-gray-600 mx-2 flex items-right justify-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                          </div>
                        {adminemail!==data?.email && <div
                          onClick={() => {setUserId(data);setIsDelete(true);setUserName(data?.firstName) }}
                          className="md:w-[100%] text-gray-600 mx-1 flex items-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            className="w-4 h-4 mx-2"
                            fill="currentColor"
                          >
                            <path
                              d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                            />
                          </svg>
                        </div>}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            //     {
            //     userDataList.map(data =>
            //     <div className="flex justify-between mb-6 mx-2 md:my-6 md:mx-10 py-8 px-8 cursor-pointer bg-theme-blue-secondary rounded-lg md:flex-row mx-0 md:mx-10 hover:scale-[1.01] dark:hover:scale-[1.01] dark:hover:bg-gray-700">
            //         <div onClick={() => setShowUserDetail(data)} className="md:w-[70%] flex flex-col justify-between leading-normal">
            //             <div className="flex flex-col items-left rounded-lg md:flex-row mx-0">
            //                 <h5 className="text-2xl font-bold tracking-tight text-gray-200 dark:text-white">{data?.firstName} {data?.lastName}</h5>
            //             </div>
            //             <p className="mb-3 font-normal text-gray-300 dark:text-gray-400">Email: {data?.email}</p>
            //             <p className="font-normal text-gray-400 dark:text-gray-400">Created At: {moment(data.createdAt).format('MMM DD YYYY hh:mm')}</p>
            //         </div>
            //         <div className="md:w-[20%] flex flex-col text-right items-center align-middle">

            //             <div onClick={() => setShowUserDetail(data)} className="md:w-[100%] text-gray-400 mx-4 flex items-right">
            //                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mx-2">
            //                     <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
            //                     <path fill-rule="evenodd" d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
            //                 </svg>
            //                 View
            //             </div>
            //             <div onClick={() => deleteForumPost(data)} className="md:w-[100%] text-gray-400 mx-4 flex items-right mt-4">
            //                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-4 h-4 mx-2">
            //                     <path fill="#9ca3af" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
            //                 </svg>
            //                 Delete
            //             </div>
            //         </div>
            //     </div>
            // )}
          )}
        </div>
      )}
    </div>
  );
};
export default UserList;
