import React, { useEffect, useState } from 'react';
import axios from "axios";
import moment from 'moment';
import { BASE_URL } from "../../config";
import StripePaymentTraining from './StripePayment';
import icon1 from "../../assets/images/calendar-schedule.svg";
import icon2 from "../../assets/images/calendar-add.svg";
import { useNavigate } from 'react-router-dom';
import { ShoppingCartIcon } from '@heroicons/react/24/outline'
import TrainingBuyCard from '../../components/trainingBuyCard';
const PersonalTraining = ({back}) => {
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [loading, setLoading] = useState(true); 
    const [sesno, setSesno] = useState(false); 
    const [receiptUrl, setReceiptUrl] = useState('');
    const [bookedTrainningSession, setBookedTrainningSession] = useState([]);
    const [showBookedTrainningSession, setShowBookedTrainningSession] = useState(true);
    const [selectedRow, setSelectedRow] = useState(false);
    const[chrgeData,setChrgeData]=useState();
    const [traningSessionDetail, setTraningSessionDetail] = useState({
        total: 0,
        booked: 0
    });

    useEffect(() => {
        let subscribedStatus = localStorage.getItem('subscribedStatus');
            
        // If subscribedStatus is falsy, redirect to membership page
        if (subscribedStatus == 'false') {
            window.location = window.location.origin + '/membership';
            return false;
        }
    });

    const navigate = useNavigate();
 
  
    const clickOnRow = (index) => {
        if (selectedRow === index) {
            setSelectedRow(false);
        } else {
            setSelectedRow(index);
        }
    };

    const getTrainingSession = () => {
        setLoading(true);
        axios({
            headers: {
                "x-access-token": localStorage.getItem('auth-token')
            },
            url: `${BASE_URL}/user/training/session`, //your url
            method: 'get',
        }).then((res) => {
            console.log("respo : ",res?.data?.data)
            setBookedTrainningSession(res?.data?.data);
            setLoading(false);
        });
    };
const fetchSessionPlan=async ()=>{
    try {
        const responce=await axios.get(`${BASE_URL}/admin/allTraining`,{
            headers: {
                "x-access-token": localStorage.getItem('auth-token'),
        }});
       
        setChrgeData(responce?.data?.data);
    } catch (error) {
        console.log("Error fetching chargeData : ",error);
    }
}
const amount= (price)=>{
    setShowPaymentForm(price)
}
const amountsess= (ses)=>{
    setSesno(ses);
}
    useEffect(() => {
        getTrainingSession();
        fetchSessionPlan();
    }, []);

    useEffect(() => {
        let totalTrainingSession = 0;
        let bookedTrainingSession = 0;
        bookedTrainningSession?.map((data) => {
            totalTrainingSession = totalTrainingSession + parseInt(data?.sessions);
            // bookedTrainingSession = bookedTrainingSession + data?.sessionTiming?.length;
            data?.sessionTiming?.filter((timing) => !timing.cancelByAdmin).forEach(() => {
                bookedTrainingSession++;
              });
        });
        setTraningSessionDetail({
            total: totalTrainingSession,
            booked: bookedTrainingSession
        });
    }, [bookedTrainningSession]);

    const downloadReceipt = async (receiptUrl) => {
        receiptUrl=`${BASE_URL}${receiptUrl}`
        window.open(receiptUrl, '_blank')
    };

    return (
        <>
            <div className="min-h-screen md:mx-10 md:h-auto">
                <div className="py-8 lg:py-10">
                {receiptUrl && (
        <iframe
          src={receiptUrl}
          width="600"
          height="400"
          title="Content Frame"
        ></iframe>
      )}
                    {showPaymentForm ? (
                        <StripePaymentTraining paymentType={showPaymentForm} sesno={sesno} setPaymentPage={() => { setShowPaymentForm(false); setShowBookedTrainningSession(true); }} setPaymentPageLoaded={() => { }} />
                    ) : (
                        <div>
                            {(bookedTrainningSession.length > 0) && (
                                <div className="p-4 md:p-6 bg-white rounded-lg">
                                    <div className="text-md md:text-lg text-black mb-8">
                                        Welcome, {localStorage.getItem('user-name')} !
                                        <br />
                                        <span className='text-[#363538] mt-2 block'>Thank you for being a part of the CFT program and training. Here is your overall journey with us!</span>
                                    </div>
                                    <div class="flex justify-between mb-4">
                                        <span class="text-md md:text-xl text-[#E53E14] dark:text-white">Your Training Sessions</span>
                                        <div>
                                            <span class="text-md md:text-xl text-[#E53E14] dark:text-white">{traningSessionDetail?.booked}/</span>
                                            <span className="text-md md:text-xl text-[#2995F7] dark:text-white">{traningSessionDetail?.total}</span>
                                        </div>
                                    </div>
                                    <div class="w-full bg-[#2995F7] rounded-full h-2 dark:bg-gray-700">
                                        <div class="bg-[#E53E14] h-2 rounded-full" style={{ width: `${((traningSessionDetail?.booked / traningSessionDetail?.total) * 100)}%` }}></div>
                                    </div>
                                    <div className="flex my-4">
                                        <div onClick={() => {navigate('/services/book/slots');back(18)}} className="bg-[#E53E14] md:w-[200px] cursor-pointer text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 rounded-lg text--md py-3 px-1 mr-4 text-center dark:text-white  dark:focus:ring-primary-900">
                                        <img src={icon2} alt="Icon" className='align-middle mr-2'/>   Book your session
                                            {false && (
                                                <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                </svg>
                                            )}
                                        </div>
                                        <div onClick={() => {navigate('/booked/sessions');back(19)}} className="bg-[#2995F7] md:w-[220px] cursor-pointer text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 rounded-lg text-md py-3 px-1 text-center dark:text-white  dark:focus:ring-primary-900">
                                        <img src={icon1} alt="Icon" className='align-middle mr-2' />    Booked History
                                            {false && (
                                                <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                </svg>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='p-4 md:p-6 bg-white rounded-lg mt-6 w-full overflow-hidden'>
                                <div className="max-w-screen-md lg:mb-8">
                                    <h2 className="mb-3 text-xl tracking-tight font-medium text-black dark:text-black">
VIDEO TRAINING </h2>
                                    <p className="mb-5 text-lg font-light text-black dark:text-black">Individuals and Employees. Hourly Video Session. Minimum exercise equipment required</p>
                                </div>
                            {chrgeData &&    <TrainingBuyCard data={chrgeData} amount={amount} ses={amountsess}/>} 
                                
                            </div>
                            {(bookedTrainningSession.length > 0) && (
                                <div className="mt-6 px-0 py-5 bg-white rounded-lg mt-6">
                                    <div className=" p-4 md:p-6 ">
                                        <h2 className="mb-3 text-xl tracking-tight font-medium text-black dark:text-black">ORDER HISTORY</h2>
                                        <p className="mb-5 text-lg font-light text-black dark:text-black"> Recent Transaction Details</p>
                                    </div>
                                    <div className="md:block hidden">
                                        <table className="w-full text-sm text-left text-slate-900 bg-white border-collapse" cellpadding="0" cellspacing="0">
                                            <thead className="text-xs uppercase bg-[#FFF5F3]">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3">
                                                        Transaction Id
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Package Plan
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Transaction Date
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Receipt
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    bookedTrainningSession.map((data, i) => (
                                                        <tr className="text-slate-900 border-0 border-solid border-b-2 border-gray-300 last:border-b-0">
                                                            <th scope="row" className="px-6 py-2 font-medium whitespace-nowrap dark:text-white">
                                                                {data?.paymentIntent}
                                                            </th>
                                                            <td className="px-6 py-2 ">
                                                                {data?.sessions} SESSION @ ${data?.amount / 100}
                                                            </td>
                                                            <td className="px-6 py-2">
                                                                {moment(data?.createdAt).format('DD MMM YYYY')}
                                                            </td>
                                                            <td onClick={() => downloadReceipt(data?.receiptUrl)} className="px-6 py-2 text-slate-600 cursor-pointer">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                                                    <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                                                    <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
                                                                </svg>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div >
                                    <div className="md:hidden block">
                                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                            <div id="accordion-color" data-accordion="collapse" data-active-classes="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white">
                                                {bookedTrainningSession?.map((data, i) => {
                                                    return (
                                                        <div className="bg-theme-blue text-gray-400 border-gray-600 dark:bg-gray-800 dark:border-gray-700">
                                                            <h2 id="accordion-color-heading-1" onClick={() => clickOnRow(i)}>
                                                                <button type="button" class={`flex items-center justify-between w-full p-5 font-medium text-left text-gray-400 border border-t-0.5 ${i + 1 === bookedTrainningSession.length ? ' border-b-0.5 ' : ' border-b-0 '} border-gray-200 dark:border-gray-700 dark:text-gray-400`} data-accordion-target="#accordion-color-body-1" aria-expanded="true" aria-controls="accordion-color-body-1">
                                                                    <span>{data?.sessions} SESSION @ ${data?.amount / 100}</span>
                                                                    <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                                        <path stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                                    </svg>
                                                                </button>
                                                            </h2>
                                                            <div id="accordion-color-body-1" class={`${selectedRow === i ? 'show' : 'hidden'} py-4 bg-[#FFF5F3]`} aria-labelledby="accordion-color-heading-1">
                                                                <div scope="row" className="px-6 pb-2 whitespace-nowrap dark:text-white">
                                                                    <span className="font-medium text-gray-400">Payment Id</span> :   {data?.paymentIntent}
                                                                </div>
                                                                <div className="px-6 pb-2">
                                                                    <span className="font-medium text-gray-400">Paymment Date</span> : {moment(data?.createdAt).format('DD MMM YYYY')}
                                                                </div>
                                                                <div onClick={() => downloadReceipt(receiptUrl)} className="px-6 pb-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                                                        <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                                                        <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                )}
                                            </div>
                                        </table >
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div >
        </>
    )
}
export default PersonalTraining;