import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import ForumDetail from './forumDetails';
import ForumEdit from './forumEdit';
import Skeleton from 'react-loading-skeleton';
import { BASE_URL } from "../config";
import 'react-loading-skeleton/dist/skeleton.css';
import forumsImg from '../assets/images/adminDumny.svg';
import DeletePopUp from '../components/deletePopUp';
import { EyeIcon, CalendarIcon, ClockIcon,  Trash } from '@heroicons/react/24/outline'


const Forums = ({handleAddForums}) => {
    const [forumDataList, setForumDataList] = useState([]);
    const [showForumDetail, setShowForumDetail] = useState('');
    const [loading, setLoading] = useState(false);
    const sortRef = useState(null);
    const sortOrderRef = useState(null);
  const [isDeletePopUp,setIsDeletePopUp]=useState(false);
  const [forumName,setForumName]=useState();
  const[fId,setFId]=useState();
  const[view,setView]=useState(false);
    const navigate = useNavigate();
    const [profileImages, setProfileImages] = useState({});
    const goBack = () => {
        setShowForumDetail('');
    }

    useEffect(() => {
        let userStatus = localStorage.getItem('status');
        let subscribedStatus = localStorage.getItem('subscribedStatus');
            
        // If subscribedStatus is falsy, redirect to membership page
        if (subscribedStatus == 'false' && userStatus != 2) {
            window.location = window.location.origin + '/membership';
            return false;
        }
    });

    const getHealthyContentList = (event) => {
        if(sortRef.current === undefined){
            var sort_by = 'updatedAt';
            var sort_order_by = '-1';
        }else{
            var sort_by = sortRef.current.value;
            var sort_order_by = sortOrderRef.current.value;
        }

        setLoading(true);
        //console.log(BASE_URL);
        axios({
            url: `${BASE_URL}/forum`, //your url
            method: 'GET',
            params: {sort: sort_by, order: sort_order_by}
        }).then((res) => {
            console.log("res    : ",res);
            setLoading(false);
            setForumDataList(res?.data?.data);
        }).catch((err) => {
            setLoading(false);
        });
    };

    useEffect(() => {
        getHealthyContentList();
    }, []);
    const yes = ()=>{
        deleteForumPost(fId);
      }
    const bb = ()=>{
        setView(false);
        setShowForumDetail('');
      }
      const cancel = ()=>{
        setIsDeletePopUp(false);
      }
    const deleteForumPost = (formData) => {
        
            axios({
                headers: {
                    "x-access-token": localStorage.getItem('auth-token')
                },
                url: `${BASE_URL}/forum`, //your url
                method: 'DELETE',
                params: {id: formData._id}
            }).then((res) => {
                setLoading(false);
                setForumDataList(res?.data?.data);
                setIsDeletePopUp(false);
            }).catch((err) => {
                setLoading(false);
            });
        // }
      };

    let isAdmin = (localStorage.getItem('roles') === "admin");


      const truncateContent = (htmlContent, wordLimit) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent;
        const textContent = tempDiv.textContent || tempDiv.innerText || "";
        const words = textContent.split(' ');
        if (words.length <= wordLimit) {
          return textContent;
        }
        return words.slice(0, wordLimit).join(' ') + ' . . . .';
      };
      

      const fetchProfileImage = (id) => {
        console.log("fetch profile image func  :   109  : ")
        axios({
          headers: {
            "x-access-token": localStorage.getItem("auth-token"),
          },
          url: `${BASE_URL}/user/profile/image/${id}`,
          method: "GET",
          responseType: "blob",
        })
          .then((res) => {
            const url = URL.createObjectURL(res.data);
            setProfileImages((prevImages) => ({ ...prevImages, [id]: url }));
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              console.log("there is no image with this user");
            }
          });
      };
      useEffect(() => {
        forumDataList.forEach((user) => {

            console.log("forum id : ",user?.author?._id,user?.author)

          fetchProfileImage(user?.author?._id);
        });
      }, [forumDataList]);

    return (
        <div className="dark:bg-slate-900 min-h-screen pb-4" >
           
            {showForumDetail ? (
                ['admin'].includes(localStorage.getItem('roles'))  ? (!view ?(
                    <ForumEdit forumData={showForumDetail} goBack={goBack} getHealthyContentList={getHealthyContentList} />)
              :  (<ForumDetail forumData={showForumDetail} goBack={bb} getHealthyContentList={getHealthyContentList} /> ) ) : (
                    <ForumDetail forumData={showForumDetail} goBack={bb} getHealthyContentList={getHealthyContentList} />
                )
            ) : (
                <div className="pt-10">
                    {isDeletePopUp  && <DeletePopUp data={`Are You Want To Delete This ${forumName}  Forum !`} yes={yes} cancel={cancel}/>}
                    <div className="mb-2 md:mb-6 md:my-0 ">
                        <div className="flex justify-between items-center border-b border-blue-200 border-opacity-20 h-14 mb-8 xl:mx-8 mx-4">
                            {/* <div className="text-2xl tracking-tight font-medium text-gray-300 dark:text-white">
                                Forums
                            </div> */}

                            <div className="mb-2 md:mb-6 md:my-0 ">
                                <label for="sort" className="font-normal text-gray-600" style={{marginLeft: '10px', marginRight: '5px'}}>Show</label>
                                <select id="sort" name="sort" onChange={() => getHealthyContentList()} ref={sortRef} className='select-default'>
                                    <option value="createdAt">Created At</option>
                                    <option value="title">Title</option>
                                </select>
                              
                           
                                <label for="sort" className="font-normal text-gray-600" style={{marginLeft: '20px', marginRight: '5px'}}>Order By</label>
                                <select id="sort_order" name="sort_order" onChange={() => getHealthyContentList()} ref={sortOrderRef} className='select-default'>
                                    <option value="1">Asc &uarr;</option>
                                    <option value="-1">Desc &darr;</option>
                                </select>
                            </div>

                            <div className="default-btn-outer mt-0">
                                {isAdmin && (
                                    <button onClick={() =>{ navigate('/forums/add') ;handleAddForums(14)}} type="button" className="default-btn mt-0 cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 pr-1">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" clip-rule="evenodd" />
                                        </svg>
                                        Add Forum
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="mb-6 mx-2 md:my-6 md:mx-10 rounded">
                            <Skeleton className="mb-4" baseColor="#ccc" highlightColor="#fff" height={140} count={4} />
                        </div>
                    ) : (
                        forumDataList.map(data =>
                        // <div className="flex  mb-6 mx-2 md:my-6 xl:mx-10 md:mx-4 xl:py-8 xl:px-8 md:py-4 md:px-4 cursor-pointer bg-white rounded-lg md:flex-row mx-0 md:mx-10 hover:scale-[1.01] dark:hover:scale-[1.01] dark:hover:bg-gray-700">

                        //     <div className='img-box mr-2'>
                        <div className="flex justify-between mb-6 mx-2 md:my-6 md:mx-10 py-4 px-4 cursor-pointer bg-white rounded-lg md:flex-row mx-0 md:mx-10 hover:scale-[1.01] dark:hover:scale-[1.01] dark:hover:bg-gray-700">

                        <div className='img-box pr-5'>
                                {/* <img src={profileImages[data.author._id]||forumsImg} alt="Image" className='mt-1 align-middle'  /> */}
                                <img
                          src={profileImages[data.author._id] || forumsImg}
                          alt="Profile"
                          className="h-10 w-10 rounded-full"
                        />
                            </div>


                            
                            <div  className="md:w-[75%] flex flex-col justify-between leading-normal">
                                <div className="flex flex-col items-left rounded-lg md:flex-row mx-0 mb-1">
                                    <h5 className="text-xl tracking-tight font-medium">{data.title}</h5>
                                </div>
                                <p className="mb-2 font-normal text-gray-500 text-xs">Author - {data.threadBy}</p>
                                <p className="mb-2 font-normal text-gray-500 text-base">{truncateContent(data.content,8)}</p>
                                </div>
                            <div className="md:w-[20%] flex flex-col text-right items-center">  
                                <p className="font-normal text-gray-600 flex text-xs">
                                    <CalendarIcon class="h-4 w-4 text-gray-600 align-middle mr-1" />
                                    {moment(data.createdAt).format('MMM DD YYYY')} 
                                    {/* <ClockIcon class="h-4 w-4 text-gray-500 align-middle mr-1 ml-5" />{moment(data.createdAt).format('hh:mm')}  */}
                                </p>
                                <div className='flex items-center'>
                                <div onClick={() => {setShowForumDetail(data);setView(true)}} className="mx-2 flex items-right rounded p-2 shadow-md shadow-gray-400 font-thin mt-4">
                                    <EyeIcon class="h-5 w-5 text-gray-800" />
                                </div>
                               
                                {['admin'].includes(localStorage.getItem('roles')) && (<>
                                
                                <div onClick={() => setShowForumDetail(data)} className="mx-2 flex items-right rounded p-2 shadow-md shadow-gray-400 font-thin mt-4">
                               
                               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-gray-800 h-5 w-5">
                               <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                               </svg>

                           </div>
                           <div onClick={() => {setFId(data);setForumName(data.title);setIsDeletePopUp(true)}} className="mx-2 flex items-right rounded p-2 shadow-md shadow-gray-400 font-thin mt-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" stroke="#4b5563" className='h-6 w-6' />
                                    </svg>
                                </div>
                                </>
                                )}
                                </div>
                            </div>
                        </div>
                    )
                    )}
                </div>
            )}
        </div>
    )
}
export default Forums;