import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import ForumDetail from './forumDetails';
import ForumEdit from './forumEdit';
import Skeleton from 'react-loading-skeleton';
import { BASE_URL } from "../config";
import 'react-loading-skeleton/dist/skeleton.css';
import forumsImg from '../assets/images/adminDumny.svg';
import DeletePopUp from '../components/deletePopUp';
import { EyeIcon, CalendarIcon, ClockIcon,  Trash } from '@heroicons/react/24/outline'


const ForumsView = ({handleAddForums}) => {
    const [forumDataList, setForumDataList] = useState([]);
    const [showForumDetail, setShowForumDetail] = useState('');
    const [loading, setLoading] = useState(false);
    const sortRef = useState(null);
    const sortOrderRef = useState(null);
  const [isDeletePopUp,setIsDeletePopUp]=useState(false);
  const [profileImages, setProfileImages] = useState({});
  const [forumName,setForumName]=useState();
  const[fId,setFId]=useState();
    const navigate = useNavigate();

    const goBack = () => {
        setShowForumDetail('');
    }

    useEffect(() => {
        let userStatus = localStorage.getItem('status');
        let subscribedStatus = localStorage.getItem('subscribedStatus');
            
        // If subscribedStatus is falsy, redirect to membership page
        if (subscribedStatus == 'false' && userStatus != 2) {
            window.location = window.location.origin + '/membership';
            return false;
        }
    });

    const getHealthyContentList = (event) => {
        if(sortRef.current === undefined){
            var sort_by = 'updatedAt';
            var sort_order_by = '-1';
        }else{
            var sort_by = sortRef.current.value;
            var sort_order_by = sortOrderRef.current.value;
        }

        setLoading(true);
        //console.log(BASE_URL);
        axios({
            url: `${BASE_URL}/forum`, //your url
            method: 'GET',
            params: {sort: sort_by, order: sort_order_by}
        }).then((res) => {
            setLoading(false);
            setForumDataList(res?.data?.data);
        }).catch((err) => {
            setLoading(false);
        });
    };

    useEffect(() => {
        getHealthyContentList();
    }, []);
    

    let isAdmin = (localStorage.getItem('roles') === "admin");
    const truncateContent = (htmlContent, wordLimit) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent;
        const textContent = tempDiv.textContent || tempDiv.innerText || "";
        const words = textContent.split(' ');
        if (words.length <= wordLimit) {
          return textContent;
        }
        return words.slice(0, wordLimit).join(' ') + ' . . . .';
      };
      
      const fetchProfileImage = (id) => {
        axios({
          headers: {
            "x-access-token": localStorage.getItem("auth-token"),
          },
          url: `${BASE_URL}/user/profile/image/${id}`,
          method: "GET",
          responseType: "blob",
        })
          .then((res) => {
            const url = URL.createObjectURL(res.data);
            setProfileImages((prevImages) => ({ ...prevImages, [id]: url }));
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              console.log("there is no image with this user");
            }
          });
      };
      useEffect(() => {
        forumDataList.forEach((user) => {
          fetchProfileImage(user?.author?._id);
        });
      }, [forumDataList]);

    return (
        <div className="dark:bg-slate-900 min-h-screen pb-4" >
            {showForumDetail ? (
                
                    <ForumDetail forumData={showForumDetail} goBack={goBack} getHealthyContentList={getHealthyContentList} />
              
            ) : (
                <div className="pt-10">
                   <div className="mb-2 md:mb-6 md:my-0 ">
                        <div className="flex justify-between items-center border-b border-blue-200 border-opacity-20 h-14 mb-8 mx-8">
                            {/* <div className="text-2xl tracking-tight font-medium text-gray-300 dark:text-white">
                                Forums
                            </div> */}

                            <div className="mb-2 md:mb-6 md:my-0 ">
                                <label for="sort" className="font-normal text-gray-600" style={{marginLeft: '10px', marginRight: '5px'}}>Show</label>
                                <select id="sort" name="sort" onChange={() => getHealthyContentList()} ref={sortRef} className='select-default'>
                                    <option value="createdAt">Created At</option>
                                    <option value="title">Title</option>
                                </select>
                              
                           
                                <label for="sort" className="font-normal text-gray-600" style={{marginLeft: '20px', marginRight: '5px'}}>Order By</label>
                                <select id="sort_order" name="sort_order" onChange={() => getHealthyContentList()} ref={sortOrderRef} className='select-default'>
                                    <option value="1">Asc &uarr;</option>
                                    <option value="-1">Desc &darr;</option>
                                </select>
                            </div>

                            
                        </div>
                    </div>
                    {loading ? (
                        <div className="mb-6 mx-2 md:my-6 md:mx-10 rounded">
                            <Skeleton className="mb-4" baseColor="#ccc" highlightColor="#fff" height={140} count={4} />
                        </div>
                    ) : (
                        forumDataList.map(data =>
                        <div className="flex justify-between mb-6 mx-2 md:my-6 md:mx-10 py-4 px-4 cursor-pointer bg-white rounded-lg md:flex-row mx-0 md:mx-10 hover:scale-[1.01] dark:hover:scale-[1.01] dark:hover:bg-gray-700">

                            <div className='img-box pr-5'>

                                {/* <img src={forumsImg} alt="Image" /> */}
                                <img
                          src={profileImages[data.author._id] || forumsImg}
                          alt="Profile"
                          className="h-10 w-10 rounded-full"
                        />
                            </div>


                            <div onClick={() => setShowForumDetail(data)} className="md:w-[75%] flex flex-col justify-between leading-normal">
                                <div className="flex flex-col items-left rounded-lg md:flex-row mx-0 mb-1">
                                    <h5 className="text-xl tracking-tight font-medium">{data.title}</h5>
                                </div>
                                <p className="mb-2 font-normal text-gray-500 text-xs">Author - {data.threadBy}</p>
                                <p className="mb-2 font-normal text-gray-500 text-base">{truncateContent(data.content,8)}</p>
                                {/* <p className="font-normal text-gray-600">
                                    <CalendarIcon class="h-5 w-5 text-gray-600 align-middle mr-1" />
                                    {moment(data.createdAt).format('MMM DD YYYY')} <ClockIcon class="h-5 w-5 text-gray-500 align-middle mr-1 ml-5" />{moment(data.createdAt).format('hh:mm')} 
                                </p> */}
                            </div>
                            <div className="md:w-[20%] flex flex-col text-right items-center">  
                                <p className="font-normal text-gray-600 flex text-xs">
                                    <CalendarIcon class="h-4 w-4 text-gray-600 align-middle mr-1" />
                                    {moment(data.createdAt).format('MMM DD YYYY')} 
                                    {/* <ClockIcon class="h-4 w-4 text-gray-500 align-middle mr-1 ml-5" />{moment(data.createdAt).format('hh:mm')}  */}
                                </p>
                                <div onClick={() => setShowForumDetail(data)} className="mx-2 flex items-right rounded p-2 shadow-md shadow-gray-400 font-thin mt-4">
                                    <EyeIcon class="h-6 w-6 text-gray-800" />
                                    {/* View */}
                                </div>
                               
                            </div>
                        </div>
                    )
                    )}
                </div>
            )}
        </div>
    )
}
export default ForumsView;